
// auth
export const POST_LOGIN = "/auth/login"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"

export const GET_DASHBOARD_DATA = "/dashboard/dashboard"

//user
export const POST_CHANGE_PASSWORD = "/dashboard/change-password"

//setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const POST_UPDATE_SETTING = "/setting/update-setting"
export const POST_GET_PRICE_FORM_OPTION = "/setting/price-form-option"
export const POST_GET_MILE_PRICE = "/setting/get-mile-price"
export const POST_SAVE_MILE_PRICE = "/setting/save-mile-price"
export const POST_GET_FIX_PRICE = "/setting/get-fix-price"
export const POST_SAVE_FIX_PRICE = "/setting/save-fix-price"

//user
export const POST_USER_LIST = process.env.REACT_APP_API_URL+"user/list"
export const DELETE_USER = "/user/delete"
export const POST_UPDATE_USER_STATUS = "/user/update-status"

//booking
export const POST_BOOKING_LIST = process.env.REACT_APP_API_URL+"booking/list"
export const POST_UPDATE_BOOKING_STATUS = process.env.REACT_APP_API_URL+"booking/update-statue"
export const POST_BOOKING_PDF = process.env.REACT_APP_API_URL+"pdf/booking?id="


//area
export const POST_AREA_LIST = process.env.REACT_APP_API_URL+"area/list"
export const DELETE_AREA = "/area/delete"
export const POST_UPDATE_AREA_STATUS = "/area/update-status"
export const POST_ADD_EDIT_AREA = "/area/add-edit"
export const POST_GET_AREA_DETAIL = "/area/detail"
