import React, { useState, useEffect } from 'react';
import { useHistory, withRouter, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import { GET_DASHBOARD_DATA } from '../../helpers/url_helper';
import Loader from './../../component/Loader';


const Dashboard = props => {
    // let history = useHistory();

    // const [HomeData, SetHomeData] = useState({});
    // const [isLoader, setLoader] = useState(0);

    // useEffect(async () => {
        // setLoader(0);
        // var res_data = await post(GET_DASHBOARD_DATA, {});
        // if (res_data.status) {
        //     SetHomeData(res_data.data);
        //     setLoader(1);
        // } else {
        //     toast.error(res_data.message);
        //     setLoader(1);
        // }
    // }, [])


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Alpha Cars | Dashboard</title>
            </Helmet>
            {/* {!isLoader && <Loader />} */}
            <div className='row'>
                <div className='col-sm-12'>
                    <h5>Welcome To Alpha Cars</h5>
                </div>
            </div>
            <div className='row mt-15'>
                {/* <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <Link to="/buyer">
                        <div className='card'>
                            <div className="card-body text-center">
                                <h6>Total Buyer</h6>
                                <h6>{HomeData.total_buyer}</h6>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <Link to="/seller">
                        <div className='card'>
                            <div className="card-body text-center">
                                <h6>Total Seller</h6>
                                <h6>{HomeData.total_seller}</h6>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <Link to="/agent">
                        <div className='card'>
                            <div className="card-body text-center">
                                <h6>Total Agent</h6>
                                <h6>{HomeData.total_agent}</h6>
                            </div>
                        </div>
                    </Link>
                </div> */}
            </div>
        </>
    );
}
export default withRouter(Dashboard);
