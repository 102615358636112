import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { POST_GET_PRICE_FORM_OPTION, POST_GET_MILE_PRICE, POST_SAVE_MILE_PRICE } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';

const ChangePassword = props => {

    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [VehicleList, SetVehicleList] = useState([]);

    const [form_inputs, setInputs] = useState({ 'vehicleId': '', prices: [] });

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            await get_form_option();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_form_option = async () => {
        var res_data = await post(POST_GET_PRICE_FORM_OPTION);
        if (res_data.status) {
            SetVehicleList(res_data.data.vehicle_list);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
    
            var res_data = await post(POST_SAVE_MILE_PRICE, form_inputs, {});
            if (res_data.status) {
                // await get_app_setting();
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = async (event) => {
        event.persist();
        setLoader(0);
        form_inputs[event.target.name] = event.target.value;
        
        setInputs({ ...form_inputs })
        
        var res_data = await post(POST_GET_MILE_PRICE, { vehicleId: form_inputs.vehicleId });
        if (res_data.status) {
            form_inputs.prices = res_data.data.price_list;
            setInputs({ ...form_inputs })
        }
        setLoader(1);

    }

    const multipleChange = async (key, event) => {

        form_inputs.prices[key][event.target.name] = event.target.value;
        setInputs({ ...form_inputs });

    }

    const add = async () => {
        form_inputs.prices.push({
            minMile: '',
            maxMile: '',
            price: ''
        })
        setInputs({ ...form_inputs })
    }

    const removeRow = async (key) => {
        form_inputs.prices.splice(key, 1);
        setInputs({ ...form_inputs });
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Alpha Cars | Mile Price Setting</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-8'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>Mile Price Setting</h6>
                        </div>
                        <div className='card-body'>
                            <div className='row mb-3'>
                                <div className='col-sm-4'></div>
                                <div className='col-sm-4'>
                                    <div className="form-group mb-3">
                                        <label className="form-label text-capitalize">Select Vehicle</label>
                                        <select className='form-control' value={form_inputs.vehicleId} onChange={handleInputChange} name='vehicleId' >
                                            <option value="">Select Vehicle</option>
                                            {VehicleList && VehicleList.map((value) => {
                                                return (<option value={value.vehicleId}>{value.vehicleName}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {form_inputs.vehicleId &&
                                <form className="av-invalid" onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className='table-responsive'>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>From Mile</th>
                                                            <th>To Mile</th>
                                                            <th>Price</th>
                                                            <th className='p-0 pb-2'>
                                                                <button onClick={add} type='button' className='btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white'>+ Add</button>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {form_inputs.prices.map((price, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <input className="form-control" maxLength={6} name='minMile' onChange={multipleChange.bind(this, key)} value={price.minMile} type="text" />
                                                                        {simpleValidator.current.message('min mile', price.minMile, 'required|numeric')}
                                                                    </td>
                                                                    <td>
                                                                        <input className="form-control" maxLength={6} name='maxMile' onChange={multipleChange.bind(this, key)} value={price.maxMile} type="text" />
                                                                        {simpleValidator.current.message('max mile', price.maxMile, 'required|numeric')}
                                                                    </td>
                                                                    <td>
                                                                        <input className="form-control" maxLength={6} name='price' onChange={multipleChange.bind(this, key)} value={price.price} type="text" />
                                                                        {simpleValidator.current.message('price', price.price, 'required|numeric')}
                                                                    </td>
                                                                    <td>
                                                                        <button type='button' className="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { removeRow(key) }} ><i className="fa fa-trash-o" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 text-center mt-4">
                                            <button disabled={ButtonDisabled} type="submit" className='btn btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Update</button>
                                            <Link to="/setting" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                        </div>
                                    </div>
                                </form>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
