import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './layouts/App_route';
import UserRoute from './layouts/UserRoute';

import { BrowserRouter } from 'react-router-dom';

import Default from './layouts/Default';
import Full from './layouts/Full';

// pages
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Logout from "./pages/Auth/Logout";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";

//users
import UsersList from "./pages/Users/UsersList";

// booking
import BookingList from "./pages/Booking/BookingList";


// setting
import Setting from "./pages/Setting/Setting";
import EmailSetting from "./pages/Setting/EmailSetting";
import ApiLogSetting from "./pages/Setting/ApiLogSetting";
import MilePrice from "./pages/Setting/MilePrice";
import FixPrice from "./pages/Setting/FixPrice";

//User Role
import AreaList from "./pages/Area/AreaList";
import AreaForm from "./pages/Area/AreaForm";

function App() {
    return (
        <>
        <BrowserRouter>
            <Switch>
                <AppRoute exact path="/login" component={Login} layout={Full} />
                <AppRoute exact path="/logout" component={Logout} layout={Full} />
                <AppRoute exact path="/forgot-password" component={ForgetPassword} layout={Full} />
                
                <UserRoute exact path="/change-password" component={ChangePassword} layout={Default} />

                {/* users */}
                <UserRoute exact path="/users" component={UsersList} layout={Default} />

                <UserRoute exact path="/booking" component={BookingList} layout={Default} />

                {/* Setting */}
                <UserRoute exact path="/setting" component={Setting} layout={Default} />
                <UserRoute exact path="/setting/email" component={EmailSetting} layout={Default} />
                <UserRoute exact path="/setting/api-log" component={ApiLogSetting} layout={Default} />
                <UserRoute exact path="/setting/mile-price" component={MilePrice} layout={Default} />
                <UserRoute exact path="/setting/fix-price" component={FixPrice} layout={Default} />

                {/* area */}
                <UserRoute exact path="/area" component={AreaList} layout={Default} />
                <UserRoute exact path="/area/add" component={AreaForm} layout={Default} />
                <UserRoute exact path="/area/edit/:id?" component={AreaForm} layout={Default} />
               
             
                <UserRoute exact path="/" component={Dashboard} layout={Default} />
            </Switch>
        </BrowserRouter> 
    </>
  );
}

export default App;
