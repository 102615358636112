import React from 'react';
import { NavLink } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
export default function Header() {

    return (
        <div className='left_nav_bar'>
            <ul className='sidebar-menu'>
                <li>
                    <NavLink exact to="/" className="has-arrow">
                        {/* <FeatherIcon icon="home" width="22" /> */}
                        <i className="fa fa-tachometer" style={{ 'fontSize': '19px', 'marginTop': '5px', 'marginRight': '8px' }} aria-hidden="true"></i>
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/users" className="has-arrow">
                        <FeatherIcon icon="users" width="22" />
                        <span>Users</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/booking" className="has-arrow">
                        <i className="fa fa-car mr-2 ml-1 mt-2" aria-hidden="true" ></i>
                        <span>Booking</span>
                    </NavLink>
                </li>
                {/*
                <li>
                    <NavLink exact to="/payment" className="has-arrow">
                        <i className="fa fa-usd mr-2 ml-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Payment</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/offers" className="has-arrow">
                        <i className="fa fa-percent mr-2 ml-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Offers</span>
                    </NavLink>
                </li> */}
                <li>
                    <a href="#SettingSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
                        <FeatherIcon icon="settings" width="22" />
                        <span style={{ 'marginTop': '2px' }}>Setting</span>
                    </a>
                    <ul className="collapse list-unstyled" id="SettingSubmenu">
                        <li><NavLink to="/area">Area</NavLink></li>
                        <li><NavLink to="/setting">Setting</NavLink></li>
                    </ul>
                </li>


            </ul>
        </div>
    );
}