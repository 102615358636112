import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom"
import { POST_BOOKING_LIST, POST_BOOKING_PDF, POST_UPDATE_BOOKING_STATUS } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken } from '../../helpers/Helper';
import { post } from '../../helpers/api_helper';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";


const ChangePassword = props => {

    let history = useHistory();
    const [BookingStausData, SetBookingStausData] = useState({ bookingId: '', bookingStatus: '' });


    useEffect(() => {
        get_list();
    }, [])

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        SetBookingStausData(inputs => ({ ...BookingStausData, [event.target.name]: event.target.value }));
    }

    //UpdateStatus
    const UpdateStatus = async () => {
        var res_data = await post(POST_UPDATE_BOOKING_STATUS, BookingStausData);
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
    }


    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_BOOKING_LIST,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [10, 11] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "bookingId" },
                    { "data": "bookingNumber" },
                    { "data": "pickupTime" },
                    { "data": "pickup_point" },
                    { "data": "dropoff_point" },
                    { "data": "userName" },
                    { "data": "vehicleName" },
                    { "data": "passengers" },
                    { "data": "paymentMethod" },
                    { "data": "price" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button onClick={() => { SetBookingStausData({ bookingId: data.bookingId, bookingStatus: data.bookingStatus }); }} data-toggle="modal" data-target="#UpdateStatusModal" className={`border-0 pt-1 pb-1 badge ${(data.bookingStatus === 'Pending') ? 'badge-info' : (data.bookingStatus === 'Confirmed') ? 'badge-warning' : ((data.bookingStatus === 'Cancelled')) ? 'badge-danger' : 'badge-success'}`}  >{data.bookingStatus}</button>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <a href={POST_BOOKING_PDF + data.pdfBookingId} target="_blank" className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-1 mb-2" ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></a>
                                <a href={`https://api.whatsapp.com/send?text=${POST_BOOKING_PDF + data.pdfBookingId}`} target="_blank" className="btn btn-sm btn-success pt-0 pb-0 pl-1 pr-1  mr-1 mb-2" ><i className="fa fa-whatsapp" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></a>
                                {/* <button className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { history.push('/area/edit/' + data.bookingId); }} ><i className="fa fa-pencil" style={{'fontSize': '12px'}} aria-hidden="true"></i></button> */}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Alpha Cars | Booking List</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Booking List</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    {/* <Link to="area/add" className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 mb-5">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Booking No</td>
                                            <td>Pickup Time</td>
                                            <td>Pickup Point</td>
                                            <td>Dropoff Point</td>
                                            <td>User Name</td>
                                            <td>Vehicle</td>
                                            <td>Passengers</td>
                                            <td>Payment Method</td>
                                            <td>Amount</td>
                                            <td>Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="modal fade" id="UpdateStatusModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Booking Status</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group mb-3">
                                <label className="form-label text-capitalize">Booking Status</label>
                                <select name="bookingStatus" className="form-control" value={BookingStausData.bookingStatus} onChange={handleInputChange}>
                                    <option value="Pending">Pending</option>
                                    <option value="Confirmed">Confirmed</option>
                                    <option value="Complete Ride">Complete Ride</option>
                                    <option value="Cancelled">Cancelled</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel</button>
                            <button onClick={UpdateStatus.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
